/* eslint-disable */
import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { Layout } from 'containers';
import { Banner } from 'components';
import { Link } from 'gatsby';
import { BASE_URL, SOCIAL, PAGES } from 'config';
import { AltPanels } from 'layouts';

class ArohaAotearoa extends PureComponent {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://starling.crowdriff.com/js/crowdriff.js";
    script.id = "cr-init__ebbbbaaf8baa7aea";
    script.async = true;
    document.querySelector(".gallery").appendChild(script);
  }

  render() {
    const social = {...SOCIAL};
    ['YOUTUBE', 'TWITTER'].forEach(e => delete social[e]);

    return (
      <Layout page="aroha-aotearoa">
        <Helmet
          title="Aroha Aotearoa"
          meta={[
            {
              name: 'description', content: 'E kitea ai ngā taonga o te moana, me mākū koe | If you seek the treasure of the ocean, you’d better get wet | (Life is for Living)',
            },
            { name: 'og:title', content: 'Aroha Aotearoa' },
            {
              name: 'og:description', content: 'E kitea ai ngā taonga o te moana, me mākū koe | If you seek the treasure of the ocean, you’d better get wet | (Life is for Living)',
            },
          ]}
          link={[{ rel: 'canonical', href: `${BASE_URL}/aroha-aotearoa/` }]}
        />

        <Banner modifier="overlay-20" backgroundImage="https://hakatours-staging.imgix.net/63ecab5afd7419000181fd5c/Northern-Trail_Haka-Tours-159-large_original.jpg">
          <h1 className="c-heading c-heading--h1 aroha-aotearoa-banner">Aroha Aotearoa</h1>
        </Banner>

        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <h2 className="c-heading c-heading--h2">Become a kaitiakitanga guardian with Haka</h2>
            <p>We celebrate culture year-round at Haka but this month we’re placing a special focus on this integral element of our tours.</p>
            <p>For Haka, culture is everything. We’re proud of our New Zealand roots and have a strong desire to share our indigenous Māori culture with you, our Haka guests. This is why we weave equal measures of culture and adventure into all our small group tours.</p>
            <p>Right now, we’re seeking our next troop of kaitiakitanga guardians to help foster the <b>Māori culture.</b></p>
            <p>To celebrate our culture, all Haka bookings in March go in the draw to win an Aotea stone necklace - then we’d love for you to share your cultural experiences on social media to further promote what Aotearoa NZ really has to offer for future travellers.</p>
          </div>
        </section>
        <AltPanels  modifier='l-container--small' items={
          [
            {
              title: 'Haka Tours',
              content: `<p>Our name “Haka” truly reflects the purpose and the cultural education that underpins all our tours. By incorporating the name “Haka” means we have a responsibility as a company to live up to the mana (power)  that this name deserves.</p>`,
              style: '',
              image: {
                width: 652,
                height: 418,
                title: 'Haka Tours',
                url: 'https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e01a/Haka_Tours_original.jpg?w=652&h=418',
                align: '',
              },
            },
            {
              title: 'Get up close to culture',
              content: `<p>Whether you’re carving a greenstone pendant, paddling a waka (Māori canoe), eating a hāngī or learning the myths and legends, we’ll ensure that you’ll gain an authentic cultural connection which will only enhance your New Zealand experience.</p>`,
              image: {
                width: 652,
                height: 418,
                title: 'Get up close to culture',
                url: 'https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e01b/Our_Culture_original.jpg?w=652&h=418',
                align: '',
              },
            },
            {
              title: 'Meet our Guides',
              content: `<p>The stars of the show! Our Haka guides share stories of <b>Māori</b> myths and legends as they travel through Aotearoa with you. Thus enabling you to gain a richer understanding of our past and how this is weaved into present-day New Zealand.</p>`,
              image: {
                width: 652,
                height: 418,
                title: 'Meet our Guides',
                url: 'https://hakatours-staging.imgix.net/5d3e2fde6dd35b000112e019/Guides_original.jpg?w=652&h=418',
                align: '',
              },
            },
          ]
        } />
        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <h2 className="c-heading c-heading--h2 mt-4">Hear from our guests</h2>
            <div className="mt-2">
              <blockquote>This tour may be the best way to see New Zealand. Ruth was an exceptional guide with a vast knowledge of New Zealand sights, history, and culture, and took us to places that I would not have thought of or been able to go as a solo traveller.</blockquote>
            </div>
          </div>
        </section>
        <section>
          <div className="l-container u-text--center">
            <div className="gallery mt-2"></div>
          </div>
        </section>
        <section className="l-section">
          <div className="l-container l-container--small u-text--center">
            <h2 className="c-heading c-heading--h2 mt-4">Follow our social channels to discover more.</h2>
            <div className="social-media mt-2">
              <ul>
                {Object.entries(social).map(([KEY, VALUE]) => (
                  <li key={KEY}>
                    <a
                      className="c-icon"
                      href={VALUE.URL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {VALUE.ICON}
                      <span className="u-sr-only">
                        {VALUE.TITLE}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <h4 className="l-alt-panels__item-title c-heading c-heading--h4 mt-4">Terms & Conditions for Haka Guests booking in March / April 2023</h4>
            <div className="fs-small">
              <p>
                <i>
                  Valid on new bookings only made from 1 March - 30 April 2023. Offer valid on any Haka Tour booked for travel 1 Mar – 31 Dec 2023.
                  New Haka customers who book in March & April will be eligible to go into the prize draw for 10x Pounamu necklaces,
                  which will be gifted at random. New Haka Guests must comment on what they are looking forward to experiencing in NZ in order to go in the draw -
                  by commenting in the Special Requests box upon booking online or emailing <a href="mailto: info@hakatours.com">info@hakatours.com</a>.
                  Gifts cannot be substituted for money or anything else. For full general T&Cs
                  visit <Link to='/booking-conditions/'>Haka Tours Ltd Terms and Conditions</Link>
                </i>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default ArohaAotearoa;
